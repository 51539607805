// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-soft-#{$color} {
        background-color: rgba(($value), 0.25) !important;
    }
}

.bg-soft-primary{
    background-color: rgba(var(--bs-primary-rgb), 0.25)!important;
}

.border-primary{
    border-color:  rgba(var(--bs-primary-rgb), 1)!important;
}

.bg-soft-dark{
    background-color: rgba(var(--#{$variable-prefix}dark-rgb),.25)!important;
}

.bg-soft-light{
    background-color: rgba(var(--#{$variable-prefix}light-rgb),.4)!important;
}