
// 
// breadcrumb.scss
//


// Breadcrumb item arrow
.breadcrumb-item {
    > a {
        color: var(--#{$variable-prefix}gray-700);
    }
    +.breadcrumb-item {
        &::before {
            font-family: "Material Design Icons";
        }
    }
}

body[data-layout-mode="dark"] {
    .breadcrumb-item>a {
        color: var(--#{$variable-prefix}gray-500);
    }

    .breadcrumb-item.active{
        color: var(--#{$variable-prefix}gray-400);
    }
}