//
// _buttons.scss
//

button,
a {
    outline: none !important;
}

.btn-outline-light {
    color: $dark;
}

.btn-primary {
    background-color: rgba(var(--bs-primary-rgb), 1);
    border-color: rgba(var(--bs-primary-rgb), 1);

    &:hover,
    &:focus,
    &:active,
    &.disabled,
    &:disabled {
        background-color: rgba(var(--bs-primary-rgb), 1);
        border-color: rgba(var(--bs-primary-rgb), 1);
        &:focus {
            box-shadow: 0 0 0 $btn-focus-width rgba(var(--bs-primary-rgb), 0.5);
        }
    }
}


.btn-outline-primary {
    color: rgba(var(--bs-primary-rgb), 1);
    border-color: rgba(var(--bs-primary-rgb), 1);
    &:hover,
    &:focus,
    &:active,
    &.disabled,
    &:disabled {
        background-color: rgba(var(--bs-primary-rgb), 1);
        border-color: rgba(var(--bs-primary-rgb), 1);
        color: $white;
        &:focus {
            box-shadow: 0 0 0 $btn-focus-width rgba(var(--bs-primary-rgb), 0.5);
        }
    }
}


//
// Soft Buttons
//

@mixin button-variant-soft($bg) {
    color: $bg;
    background-color: rgba($bg, 0.1);
    border-color: transparent;

    &:hover,
    &:focus,
    &:active {
        color: $white;
        background-color: $bg;
    }

    &:focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($bg, 0.5);
    }
}

@each $color, $value in $theme-colors {
    .btn-soft-#{$color} {
        @include button-variant-soft($value);
    }
}

.btn-soft-primary {
    color: rgba(var(--bs-primary-rgb), 1);
    background-color: rgba(var(--bs-primary-rgb), 0.1);
    &:hover,
    &:focus,
    &:active {
        background-color: rgba(var(--bs-primary-rgb), 1);
        &:focus {
            box-shadow: 0 0 0 $btn-focus-width rgba(var(--bs-primary-rgb), 0.5);
        }
    }
}

.btn-soft-light {
    color: $dark;
    background-color: rgba($light, 0.25);
    &:hover,
    &:focus,
    &:active {
        color: $dark;
    }
}

//
// Split Dropdown buttons
//

@mixin button-variant-split($bg) {
    background-color: lighten($bg, 5%);
    border: none;
}

@each $color, $value in $theme-colors {
    .btn-#{$color}.dropdown-toggle-split {
        @include button-variant-split($value);
    }
}

// Rounded Buttons
.btn-rounded {
    border-radius: 30px;
}

// example 1

.btn-group-example {
    .btn {
        position: relative;

        &:first-child {
            &::before {
                display: none;
            }
        }

        &:before {
            content: "OR";
            position: absolute;
            font-size: 10px;
            width: 22px;
            height: 22px;
            line-height: 22px;
            border-radius: 50%;
            background-color: $gray-600;
            color: $light;
            left: -12px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }
}

// Example 2

.btn-label {
    position: relative;
    padding-left: 44px;
    border: none;

    .label-icon {
        position: absolute;
        width: 32px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba($white, 0.15);
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.btn-light {
        .label-icon {
            background-color: rgba($dark, 0.1);
        }
    }

    &.btn-soft-light {
        .label-icon {
            background-color: rgba($dark, 0.05);
        }
    }
}

// theme dark
[data-layout-mode="dark"] {
    .btn-light {
        color: var(--#{$variable-prefix}dark);
        background-color: var(--#{$variable-prefix}light);
        border-color: var(--#{$variable-prefix}light);
        &:hover {
            color: var(--#{$variable-prefix}dark);
            background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
            border-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
        }
    }

    .btn-check:focus + .btn-light,
    .btn-check:focus + .btn-outline-light,
    .btn-check:focus + .btn-soft-light,
    .btn-light:focus,
    .btn-outline-light:focus,
    .btn-soft-light:focus {
        color: var(--#{$variable-prefix}dark);
        background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
        border-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
    }

    .btn-check:active + .btn-light,
    .btn-check:active + .btn-outline-light,
    .btn-check:active + .btn-soft-light,
    .btn-check:checked + .btn-light,
    .btn-check:checked + .btn-outline-light,
    .btn-check:checked + .btn-soft-light,
    .btn-light.active,
    .btn-light:active,
    .btn-outline-light.active,
    .btn-outline-light:active,
    .btn-soft-light.active,
    .btn-soft-light:active,
    .show > .btn-light.dropdown-toggle,
    .show > .btn-outline-light.dropdown-toggle,
    .show > .btn-soft-light.dropdown-toggle {
        color: var(--#{$variable-prefix}dark);
        background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
        border-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
    }

    // dark

    .btn-dark {
        color: var(--#{$variable-prefix}light);
        background-color: var(--#{$variable-prefix}dark);
        border-color: var(--#{$variable-prefix}dark);
        &:hover {
            color: var(--#{$variable-prefix}light);
            background-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
            border-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
        }
    }

    .btn-check:focus + .btn-dark,
    .btn-check:focus + .btn-outline-dark,
    .btn-check:focus + .btn-soft-dark,
    .btn-dark:focus,
    .btn-outline-dark:focus,
    .btn-soft-dark:focus {
        color: var(--#{$variable-prefix}light);
        background-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
        border-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
    }

    .btn-check:active + .btn-dark,
    .btn-check:active + .btn-outline-dark,
    .btn-check:active + .btn-soft-dark,
    .btn-check:checked + .btn-dark,
    .btn-check:checked + .btn-outline-dark,
    .btn-check:checked + .btn-soft-dark,
    .btn-dark.active,
    .btn-dark:active,
    .btn-outline-dark.active,
    .btn-outline-dark:active,
    .btn-soft-dark.active,
    .btn-soft-dark:active,
    .show > .btn-dark.dropdown-toggle,
    .show > .btn-outline-dark.dropdown-toggle,
    .show > .btn-soft-dark.dropdown-toggle {
        color: var(--#{$variable-prefix}light);
        background-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
        border-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
    }

    // outline

    .btn-outline-light {
        color: var(--#{$variable-prefix}dark);
        border-color: var(--#{$variable-prefix}light);
        &:hover {
            color: var(--#{$variable-prefix}dark);
            background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
            border-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
        }
    }

    .btn-outline-dark {
        color: var(--#{$variable-prefix}dark);
        border-color: var(--#{$variable-prefix}dark);
        &:hover {
            color: var(--#{$variable-prefix}light);
            background-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
            border-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.75);
        }
    }

    // soft

    .btn-soft-light {
        color: var(--#{$variable-prefix}dark);
        background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.1);
        &:hover {
            color: var(--#{$variable-prefix}dark);
            background-color: var(--#{$variable-prefix}light);
        }
    }

    .btn-soft-secondary {
        color: var(--#{$variable-prefix}gray-600);
    }

    .btn-soft-dark {
        color: var(--#{$variable-prefix}dark);
        background-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.1);
        &:hover {
            color: var(--#{$variable-prefix}light);
            background-color: var(--#{$variable-prefix}dark);
        }
    }
}
